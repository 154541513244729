import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDeleteBookingMutation } from "../../services/BookingApi";
import { toast } from "react-toastify";

const DeleteBookingDialog = (props) => {
  const { open, setOpen, lang, classes, itemToDelete, setErrorMessage } = props;
  const [deletePost, { isLoading }] = useDeleteBookingMutation();
  const itemDeleteClicked = () => {
    deletePost(itemToDelete)
      .unwrap()
      .then((res) => {
        setOpen(false);
        toast.success(lang.bookingDeleted);
      })
      .catch((err) => {
        setOpen(false);
        if (err?.status === 403) {
          setErrorMessage(lang.bookingForbidden);
        } else {
          toast.error(lang.bookingDeleteError);
        }
      });
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
      className={classes.deleteDialog}
    >
      {isLoading && <LinearProgress />}
      <DialogTitle id="alert-dialog-title">{lang.sureConfirmMsg}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          {lang.cancel}
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => itemDeleteClicked()}
          color="secondary"
        >
          {lang.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBookingDialog;
