import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "auth",
  initialState: { token: localStorage.getItem('user_token') } ,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }
    ) => {
      localStorage.setItem('user_token', token);
      state.token = token;
    },
    logout: (state) => {
      localStorage.removeItem('user_token');
      state.token = null;
    }
  },
  extraReducers: (builder) => {}
});

export const { setCredentials,logout } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.auth.token;
