import DateFnsUtils from "@date-io/date-fns";
import {
  Container,
  CssBaseline,
  Grid,
  MenuItem,
  Slider,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { getCurrentLanguage } from "../../features/config/configSlice";
import { useGetBookingsQuery } from "../../services/BookingApi";
import apiConfig from "../../utilities/Constants";
import { extractNumber } from "../../utilities/Utils";
import * as data from "./../locales/default.json";
import BookingLinesTable from "./BookingLinesTable";
import DeleteBookingDialog from "./DeleteBookingDialog";
import ErrorDialog from "./ErrorDialog";
import { eachMinuteOfIntervalWithOptions } from "date-fns/fp";

const ROWS_PER_PAGE = 10;
const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 660,
    backgroundColor: "#112240",
    paddingBottom: "20px",
    "& .MuiPaginationItem-root": {
      color: "#64ffda !important",
    },
  },
  selectText: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: "#a8b2d1",
    },
    "& .MuiInputLabel-root": {
      color: "#a8b2d1",
    },
    "& .MuiFormLabel-root": {
      background: "#112240",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a8b2d1",
    },
    "& .MuiIconButton-root": {
      padding: "8px",
      color: "#dfdfdf",
    },
    "& .MuiSvgIcon-colorAction": {
      color: "#dfdfdf",
    },
    "&:active .MuiSvgIcon-colorAction": {
      color: "#64ffda",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#a8b2d1",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#a8b2d1",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a8b2d1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#64ffda",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#64ffda",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#64ffda",
    },
  },
  createButtonHolder: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  createButton: {
    background: "#0c987e",
    borderRadius: 3,
    border: "1px solid #64ffda",
    color: "white",

    height: "auto",
    padding: "8px",
    "& > .MuiButton-label": {
      fontSize: "0.75rem",
    },
    "&:hover": {
      backgroundColor: "#1bb598",
    },
    "&.Mui-disabled": {
      color: "white",
    },
  },
  container: {
    maxWidth: "90%",
    marginTop: "30px",
    borderRadius: "10px",
    paddingBottom: "24px",
  },
  pageHeader: {
    fontSize: "42px",
    color: "#ccd6f6",
    paddingTop: "20px",
  },
  table: {
    minWidth: 1250,
  },
  tableContainer: {
    marginTop: "28px",
  },
  tablerow: {
    backgroundColor: "rgb(27, 46, 78)",
  },
  dateIcon: {
    color: "red",
    background: "green",
  },
  icon: {
    color: "#64ffda",
  },
  tablecell: {
    color: "#ccd6f6",
  },
  pdfButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  pdfButtonIcon: {
    color: "#64ffda",
    marginLeft: "10px",
  },
  deleteDialog: {
    "& .MuiPaper-root": {
      background: "#cdd5e3",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      background: "#cdd5e3",
      height: "100%",
    },
    "& .MuiDialogContent-root": {
      overflow: "hidden",
    },
  },
  noRecord: {
    padding: "20px 0",
  },
  checkboxLabel: {
    color: '#a8b2d1',
    '& .MuiCheckbox-root': {
      color: '#64ffda',
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#64ffda',
    },
  },
});

const List = () => {
  const location = useLocation();
  const history = useHistory();
  const { pageNumber = 1 } = useParams();
  const params = new URLSearchParams(location.search);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(params.get("term"));
  const [sorting, setSorting] = useState("id");
  const [order, setOrder] = useState("asc");
  const [field, setField] = useState(params?.get("field") ?? "total");
  const [amount, setAmount] = useState(params.get("amount") ?? "");
  const [operator, setOperator] = useState(params.get("operator") ?? "gt");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState();
  const [showBookedInvoices, setShowBookedInvoices] = useState(false);
  const classes = useStyles();
  const language = useSelector(getCurrentLanguage);
  const getSortParams = () => {
    let filter =
      params.get("field") && params.get("amount")
        ? `${params.get("term") ||
          (params.get("sort") && params.get("sort") != "id")
          ? "&"
          : "?"
        }field=${params.get("field")}&amount=${params.get(
          "amount"
        )}&operator=${params.get("operator")}`
        : "";
    if (params.get("sort")) {
      if (params.get("sort") == "id") {
        history.replace({
          pathname: location.pathname,
          search: `${params.get("term") ? `?term=${params.get("term")}` : ""
            }${filter}${showBookedInvoices ? '&includeBooked=true' : ''}`,
        });
        return `${params.get("term") ? `?term=${params.get("term")}` : ""
          }${filter}${showBookedInvoices ? '&includeBooked=true' : ''}`;
      }
      return `?sort=${params.get("sort")}${params.get("term") ? `&term=${params.get("term")}` : ""
        }${filter}${showBookedInvoices ? '&includeBooked=true' : ''}`;
    }
    return `${params.get("term") ? `?term=${params.get("term")}` : ""
      }${filter}${showBookedInvoices ? '&includeBooked=true' : ''}`;
  };
  const { data: { records: bookings, totalCount } = {} } =
    useGetBookingsQuery({
      page: pageNumber,
      perPage: ROWS_PER_PAGE,
      sort: getSortParams(),
    }) || {};
  const downloadFile = async (fileName) => {
    setLoading(true);
    try {
      const url = `${apiConfig.BASE_API}/pdfs`;
      const params = {
        name: fileName,
      };
      const response = await axios.get(url, {
        responseType: "arraybuffer",
        params,
      });
      if (response.status === 200) {
        const contentType = response.headers["content-type"];
        const pdfBlob = new Blob([response.data], { type: contentType });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setFile(pdfUrl);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error loading PDF:", error);
    }
  };
  const fileViewerCliced = (name) => {
    setOpen(true);
    setFileName(name);
    setFile(null);
    downloadFile(name);
  };
  const deleteItemClicked = (item) => {
    setItemToDelete(item);
    setOpenDeleteDialog(true);
  };
  const TableSortChanged = (selectedColumn, sortDirection) => {
    setSorting(selectedColumn?.sortField);
    setOrder(sortDirection);
    let filter = "";
    if (amount) {
      filter = `&field=${field}&amount=${["date", "createdAt"].includes(field)
          ? new Date(amount).toISOString().split("T")[0]
          : amount
        }&operator=${operator}`;
    }
    const newSearch = `?sort=${sortDirection == "desc" ? "-" : ""}${selectedColumn?.sortField
      }${searchTerm ? `&term=${searchTerm}` : ""}${filter}${showBookedInvoices ? '&includeBooked=true' : ''}`;
    history.replace({ pathname: location.pathname, search: newSearch });
  };
  const searchClicked = (amount, operator, field) => {
    let filter = "";
    if (amount) {
      filter = `&field=${field}&amount=${["date", "createdAt"].includes(field)
          ? new Date(amount).toISOString().split("T")[0]
          : amount
        }&operator=${operator}`;
    }
    const newSearch = `?sort=${order == "desc" ? "-" : ""}${sorting}${searchTerm ? `&term=${searchTerm}` : ""
      }${filter}${showBookedInvoices ? '&includeBooked=true' : ''}`;
    history.replace({ pathname: "/booking/1", search: newSearch });
  };
  const handleDateChange = async (date) => {
    setAmount(date);
    let filter = "";
    if (date) {
      filter = `&field=${field}&amount=${new Date(date).toISOString().split("T")[0]
        }&operator=${operator}`;
    }
    const newSearch = `?sort=${order == "desc" ? "-" : ""}${sorting}${searchTerm ? `&term=${searchTerm}` : ""
      }${filter}${showBookedInvoices ? '&includeBooked=true' : ''}`;
    history.replace({ pathname: "/booking/1", search: newSearch });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchClicked(amount, operator, field);
    }
  };
  const fieldChanged = (e) => {
    setAmount(null);
    setField(e.target.value);
    searchClicked(null, operator, e.target.value);
  };
  const operatorChanged = (e) => {
    setOperator(e.target.value);
    if (amount) {
      searchClicked(amount, e.target.value, field);
    }
  };
  const getSliderSize = (pageNumber) => {
    if (pageNumber <= 10) {
      return 2;
    }
    if (pageNumber <= 20) {
      return 3;
    }
    if (pageNumber <= 30) {
      return 4;
    }
    if (pageNumber <= 50) {
      return 6;
    }
    if (pageNumber <= 70) {
      return 7;
    }
    if (pageNumber <= 80) {
      return 8;
    }
    if (pageNumber <= 90) {
      return 9;
    }
    return 10;
  };
  const handleShowBookedInvoicesChange = (event) => {
    setShowBookedInvoices(event.target.checked);
    // Trigger a new search with updated parameters
    searchClicked(amount, operator, field);
  };
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      minWidth: "65px",
      grow: "0",
      sortField: "id",
    },
    {
      name: data.default[language].fields.company,
      selector: (row) => row.company.name,
      sortable: true,
      grow: "0",
      sortField: "company",
    },
    {
      name: data.default[language].fields.invoiceNumber,
      selector: (row) => row.invoiceNumber,
      sortable: true,
      sortField: "invoiceNumber",
    },
    {
      name: data.default[language].fields.paidBy,
      selector: (row) => row.paidBy?.name ?? "-",
      sortable: true,
      sortField: "paidById",
      grow: 0,
    },
    {
      name: data.default[language].fields.invoiceDate,
      selector: (row) => moment(row.invoiceDate).format("MM/DD/YYYY"),
      sortable: true,
      minWidth: "125px",
      grow: "0",
      sortField: "invoiceDate",
    },
    {
      name: data.default[language].fields.description,
      selector: (row) => row.description,
      sortable: true,
      maxWidth: "160px",
      sortField: "description",
    },
    {
      name: data.default[language].total,
      selector: (row) => {
        const totalAmount = row.bookingLines
          ?.map(
            (item) =>
              extractNumber(item.amountInEUR) +
              extractNumber(item.taxAmountInEUR)
          )
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        return "€" + parseFloat(totalAmount).toFixed(2);
      },
      sortable: true,
      grow: 0,
      maxWidth: "160px",
      sortField: "total",
    },
    {
      name: data.default[language].fields.taxRate,
      selector: (row) => {
        const totalAmount =
          row.bookingLines
            ?.map((item) => extractNumber(item.taxAmountInEUR))
            .reduce((acc, curr) => acc + curr, 0) ?? 0;
        return "€" + parseFloat(totalAmount).toFixed(2);
      },
      grow: 0,
      sortable: true,
      sortField: "totalTax",
    },

    {
      name: data.default[language].fields.image,
      selector: (row) => row.pdfName,
      sortField: "pdfName",
      sortable: true,
      minWidth: "85px",
      maxWidth: "10px",
      grow: "0",
      style: {
        margin: "0px !important",
      },
      cell: (row, index, column, id) => {
        return (
          <div
            onClick={() => fileViewerCliced(row.pdfName)}
            className={classes.pdfButton}
          >
            <VisibilityIcon
              className={classes.pdfButtonIcon}
              fontSize="small"
            />
          </div>
        );
      },
    },
    {
      name: data.default[language].fields.vendorName,
      selector: (row) => row.vendorName,
      sortable: true,
      sortField: "vendorName",
    },
    {
      name: data.default[language].fields.createdAt,
      selector: (row) => moment(row.createdAt).format("MM/DD/YYYY hh:mm"),
      sortable: true,
      sortField: "createdAt",
    },
    {
      name: data.default[language].fields.actions,
      selector: (row) => row.id,
      cell: (row, index, column, id) => {
        return (
          <Grid
            container
            width="100%"
            justifyContent="space-evenly"
            alignItems="start"
          >
            <Grid item xs={4}>
              <DeleteForeverIcon
                onClick={() => deleteItemClicked(row.id)}
                color="error"
                fontSize="small"
              />
            </Grid>
            <Grid item xs={4}>
              <Link to={`/edit/${row.id}`}>
                <EditIcon color="primary" fontSize="small" />
              </Link>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <>
      <CssBaseline />
      <main>
        <div>
          <Container
            className={classes.container}
            maxWidth="xl"
            fullWidth
            style={{ background: "#112240" }}
          >
            <Typography
              className={classes.pageHeader}
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {data.default[language].booking}
            </Typography>
            <Box display="flex" flexDirection="column" flex={1}>
              <Grid container className={classes.createButtonHolder}>
                <Grid item container xs={10} spacing={4} alignItems="center">
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showBookedInvoices}
                          onChange={handleShowBookedInvoicesChange}
                          name="showBookedInvoices"
                          color="primary"
                        />
                      }
                      label="Invoices in GL"
                      className={classes.checkboxLabel}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      margin="dense"
                      value={searchTerm ?? ""}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      name="searchTerm"
                      className={classes.selectText}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <SearchIcon
                              onClick={() =>
                                searchClicked(amount, operator, field)
                              }
                              color="action"
                            />
                          </IconButton>
                        ),
                      }}
                      label={data.default[language].filter.search}
                      type="text"
                      id="searchTerm"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      size="small"
                      margin="dense"
                      className={classes.selectText}
                      value={field}
                      onChange={fieldChanged}
                      name="field"
                      select
                      label={data.default[language].filter.field}
                      type="text"
                      SelectProps={{
                        classes: { icon: classes.icon },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value={"id"}>
                        {data.default[language].fields.id}
                      </MenuItem>
                      <MenuItem value={"taxrate"}>
                        {data.default[language].fields.taxRate}
                      </MenuItem>
                      <MenuItem value={"total"}>
                        {data.default[language].total}
                      </MenuItem>
                      <MenuItem value={"date"}>
                        {data.default[language].fields.invoiceDate}
                      </MenuItem>
                      <MenuItem value={"createdAt"}>
                        {data.default[language].fields.createdAt}
                      </MenuItem>
                    </TextField>
                  </Grid>
                  {field !== "id" && (
                    <Grid item xs={2}>
                      <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        className={classes.selectText}
                        value={operator}
                        onChange={operatorChanged}
                        name="operator"
                        select
                        label={data.default[language].filter.operator}
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        SelectProps={{
                          classes: { icon: classes.icon },
                        }}
                      >
                        <MenuItem value={"gt"}>
                          {["date", "createdAt"].includes(field)
                            ? data.default[language].filter.after
                            : data.default[language].filter.bigger}
                        </MenuItem>
                        <MenuItem value={"eq"}>
                          {data.default[language].filter.equal}
                        </MenuItem>
                        <MenuItem value={"lt"}>
                          {["date", "createdAt"].includes(field)
                            ? data.default[language].filter.before
                            : data.default[language].filter.less}
                        </MenuItem>
                      </TextField>
                    </Grid>
                  )}

                  <Grid item xs={3}>
                    {["date", "createdAt"].includes(field) ? (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          placeholder="MM/dd/yyyy"
                          inputVariant="outlined"
                          size="small"
                          margin="dense"
                          format="MM/dd/yyyy"
                          className={classes.selectText}
                          onAccept={handleDateChange}
                          label={
                            field === "date"
                              ? data.default[language].fields.invoiceDate
                              : data.default[language].fields.createdAt
                          }
                          value={amount}
                          InputLabelProps={{
                            shrink: true,
                            classes: {
                              root: classes.cssLabel,
                            },
                          }}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "Change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    ) : (
                      <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        value={amount ?? ""}
                        onChange={(e) => setAmount(e.target.value)}
                        onKeyPress={handleKeyPress}
                        name="searchTerm"
                        className={classes.selectText}
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <SearchIcon
                                onClick={() =>
                                  searchClicked(amount, operator, field)
                                }
                                color="action"
                              />
                            </IconButton>
                          ),
                        }}
                        label={
                          field === "id"
                            ? data.default[language].fields.id
                            : data.default[language].filter.amount
                        }
                        type="number"
                        id="filterAmount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Link to="/create">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    classes={{
                      root: classes.createButton,
                    }}
                  >
                    {data.default[language].create}
                  </Button>
                </Link>
              </Grid>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <DataTable
                  highlightOnHover
                  pointerOnHover
                  expandOnRowClicked
                  sortServer
                  theme="dark"
                  onSort={(selectedColumn, sortDirection) =>
                    TableSortChanged(selectedColumn, sortDirection)
                  }
                  expandableRows
                  expandableRowsComponent={BookingLinesTable}
                  noDataComponent={
                    <p className={classes.noRecord}>
                      {data.default[language].noRecord}
                    </p>
                  }
                  columns={columns}
                  data={bookings}
                />
              </TableContainer>
            </Box>
            {Math.ceil(totalCount / ROWS_PER_PAGE) > 1 && (
              <>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  flex={1}
                  padding={1}
                  paddingRight={10}
                >
                  <Grid
                    item
                    xs={getSliderSize(Math.ceil(totalCount / ROWS_PER_PAGE))}
                  >
                    <Slider
                      aria-label="Temperature"
                      value={Number(pageNumber)}
                      defaultValue={Number(pageNumber)}
                      getAriaValueText={() => Number(pageNumber).toString()}
                      valueLabelDisplay="auto"
                      onChangeCommitted={(e, value) => {
                        history.push(`/booking/${value}${getSortParams()}`);
                      }}
                      step={1}
                      marks
                      min={1}
                      max={Math.ceil(totalCount / ROWS_PER_PAGE) || 1}
                    />
                  </Grid>
                </Box>
              </>
            )}
          </Container>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="lg"
            height="100%"
            className={classes.dialog}
          >
            {loading && <LinearProgress />}
            <DialogTitle id="alert-dialog-title">{fileName}</DialogTitle>
            {file && (
              <DialogContent>
                {fileName?.toLowerCase()?.endsWith(".pdf") ? (
                  <iframe
                    title={`PDF Viewer`}
                    src={file + "#navpanes=0&statusbar=0&toolbar=0&view=FitH"}
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <div className="imagePreviewContainer small">
                    <img className="imagePreview" src={file} />
                  </div>
                )}
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                {data.default[language].close}
              </Button>
            </DialogActions>
          </Dialog>
          <DeleteBookingDialog
            response
            classes={classes}
            lang={data.default[language]}
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            itemToDelete={itemToDelete}
            setErrorMessage={setErrorMessage}
          />
          <ErrorDialog
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            lang={data.default[language]}
          />
        </div>
      </main>
    </>
  );
};

export default List;
