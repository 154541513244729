import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, TextField, MenuItem } from "@material-ui/core";
import {useDispatch,useSelector} from 'react-redux'
import { setLanguage,getCurrentLanguage } from "../features/config/configSlice";
const useStyles = makeStyles({
  root: {
    width: "200px",
    position: "relative",
    marginLeft: "2rem",
    maxWidth: 360,
    "& label.Mui-focused": {
      color: "#64ffda",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#64ffda",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#a8b2d1",
      },
      "&:hover fieldset": {
        borderColor: "#a6a0b1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#64ffda",
      },
    },
  },
  icon: {
    color: "#64ffda",
  },
  selectText: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: "#a8b2d1",
    },
    "& .MuiInputLabel-root": {
      color: "#a8b2d1",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a8b2d1",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#a8b2d1",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#a8b2d1",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a8b2d1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#64ffda",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#64ffda",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#64ffda",
    },
  },
});

const Language = () => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const language = useSelector(getCurrentLanguage);

  const handleChange = (event) => {
    dispatch(setLanguage(event.target.value))
  };
  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <TextField
          className={classes.selectText}
          SelectProps={{
            classes: { icon: classes.icon },
          }}
          value={language}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          size="small"
          label="Language"
          select
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"nl"}>Dutch</MenuItem>
        </TextField>
      </div>
    </>
  );
};

export default Language;
