import React, { useState, useEffect } from "react";
import * as data from "./locales/default.json";
import { Typography, CssBaseline, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { Link, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import axios from "axios";
import moment from "moment";
import apiConfig from "../utilities/Constants";
import { getCurrentLanguage } from "../features/config/configSlice";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 660,
    backgroundColor: "#112240",
    paddingBottom: "20px",
    "& .MuiPaginationItem-root": {
      color: "#64ffda !important",
    },
  },
  container: {
    marginTop: "30px",
    borderRadius: "10px",
    paddingBottom: "24px",
  },
  pageHeader: {
    fontSize: "42px",
    color: "#ccd6f6",
    paddingTop: "20px",
  },
  table: {
    minWidth: 1250,
  },
  tableContainer: {
    marginTop: "50px",
  },
  tablerow: {
    backgroundColor: "rgb(27, 46, 78)",
  },
  tablecell: {
    color: "#ccd6f6",
  },
});

function createData(
  id,
  personeelsnr,
  volgnr,
  bsn,
  hoofdgroep,
  docgroep,
  doctype,
  tabblad,
  datumdoc,
  documentnaam,
  pdfnaam,
  opmerking,
  datumanmaak,
  tijd,
  gebruiker
) {
  return {
    id,
    personeelsnr,
    volgnr,
    bsn,
    hoofdgroep,
    docgroep,
    doctype,
    tabblad,
    datumdoc,
    documentnaam,
    pdfnaam,
    opmerking,
    datumanmaak,
    tijd,
    gebruiker,
  };
}

const TableLog = (props) => {
  const classes = useStyles();
  const language = useSelector(getCurrentLanguage);
  var defaultSortOrder = "asc";
  var defaultSortBy = "id";
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const ROWS_PER_PAGE = 10;

  const { pageNumber = 1 } = useParams();

  const requestSort = async (order) => {
    let sortByTemp = order ? order : "id";
    let sortOrderTemp = sortOrder === "asc" ? "desc" : "asc";

    try {
      const res = await axios.get(`${apiConfig.BASE_API}/log`, {
        params: {
          page: pageNumber,
          perPage: ROWS_PER_PAGE,
          sort: sortOrderTemp,
          order: sortByTemp,
        },
      });
      let data = res.data;
      let tempData = [];
      data.logs.forEach((d, index) => {
        tempData.push(
          createData(
            d.id,
            d.personeelsnr,
            d.volgnr,
            d.bsn,
            d.hoofdgroep,
            d.docgroep,
            d.doctype,
            d.tabblad,
            d.datumdoc,
            d.documentnaam,
            d.pdfnaam,
            d.opmerking,
            moment(d.createdAt.toString()).format("MM/DD/YYYY"),
            moment(d.createdAt.toString()).format("HH:mm"),
            d.gebruiker
          )
        );
      });
      setRows(tempData);
      setSortOrder(sortOrderTemp);
      setTotalCount(data.totalCount);
      setSortBy(sortByTemp);
    } catch (err) {
      console.log(err);
    }
  };

  const requestSortId = () => {
    requestSort("id");
  };

  const requestSortBsn = () => {
    requestSort("bsn");
  };

  const requestPersoneelsnr = () => {
    requestSort("personeelsnr");
  };

  const requestVolgnr = () => {
    requestSort("volgnr");
  };

  const requestHoofdgroep = () => {
    requestSort("hoofdgroep");
  };

  const requestDocgroep = () => {
    requestSort("docgroep");
  };

  const requestDoctype = () => {
    requestSort("doctype");
  };

  const requestTabblad = () => {
    requestSort("tabblad");
  };

  const requestDatumdoc = () => {
    requestSort("datumdoc");
  };

  const requestDocumentnaam = () => {
    requestSort("documentnaam");
  };

  const requestPdfnaam = () => {
    requestSort("pdfnaam");
  };

  const requestOpmerking = () => {
    requestSort("opmerking");
  };

  const requestGebruiker = () => {
    requestSort("gebruiker");
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${apiConfig.BASE_API}/log`, {
        params: {
          page: pageNumber,
          perPage: ROWS_PER_PAGE,
          sort: sortOrder,
          order: sortBy,
        },
      });
      let data = res.data;
      let tempData = [];
      data.logs.forEach((d, index) => {
        tempData.push(
          createData(
            d.id,
            d.personeelsnr,
            d.volgnr,
            d.bsn,
            d.hoofdgroep,
            d.docgroep,
            d.doctype,
            d.tabblad,
            d.datumdoc,
            d.documentnaam,
            d.pdfnaam,
            d.opmerking,
            moment(d.createdAt.toString()).format("MM/DD/YYYY"),
            moment(d.createdAt.toString()).format("HH:mm"),
            d.gebruiker
          )
        );
      });
      setRows(tempData);
      setTotalCount(data.totalCount);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [pageNumber]);
  return (
    <>
      <CssBaseline />

      <main>
        <div>
          <Container
            className={classes.container}
            style={{ background: "#112240" }}
            maxWidth="l"
          >
            <Typography
              className={classes.pageHeader}
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {"Audit trail"}
            </Typography>
            <Box display="flex" flexDirection="column" flex={1}>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow className={classes.tablerow}>
                      <TableCell className={classes.tablecell}>
                        <TableSortLabel
                          active={sortBy === "id"}
                          direction={sortOrder}
                          onClick={requestSortId}
                        >
                          Id
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "personeelsnr"}
                          direction={sortOrder}
                          onClick={requestPersoneelsnr}
                        >
                          {data.default[language].table.personeelsnr}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "volgnr"}
                          direction={sortOrder}
                          onClick={requestVolgnr}
                        >
                          {data.default[language].table.volgnr}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "bsn"}
                          direction={sortOrder}
                          onClick={requestSortBsn}
                        >
                          {data.default[language].table.bsn}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "hoofdgroep"}
                          direction={sortOrder}
                          onClick={requestHoofdgroep}
                        >
                          {data.default[language].table.hoofdgroep}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "docgroep"}
                          direction={sortOrder}
                          onClick={requestDocgroep}
                        >
                          {data.default[language].table.docgroep}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "doctype"}
                          direction={sortOrder}
                          onClick={requestDoctype}
                        >
                          {data.default[language].table.doctype}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "tabblad"}
                          direction={sortOrder}
                          onClick={requestTabblad}
                        >
                          {data.default[language].table.tabblad}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "datumdoc"}
                          direction={sortOrder}
                          onClick={requestDatumdoc}
                        >
                          {data.default[language].table.datumdoc}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "documentnaam"}
                          direction={sortOrder}
                          onClick={requestDocumentnaam}
                        >
                          {data.default[language].table.documentnaam}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "pdfnaam"}
                          direction={sortOrder}
                          onClick={requestPdfnaam}
                        >
                          {data.default[language].table.pdfnaam}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "opmerking"}
                          direction={sortOrder}
                          onClick={requestOpmerking}
                        >
                          {data.default[language].table.opmerking}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "id"}
                          direction={sortOrder}
                          onClick={requestSortId}
                        >
                          {data.default[language].table.datumanmaak}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "id"}
                          direction={sortOrder}
                          onClick={requestSortId}
                        >
                          {data.default[language].table.tijd}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tablecell} align="right">
                        <TableSortLabel
                          active={sortBy === "gebruiker"}
                          direction={sortOrder}
                          onClick={requestGebruiker}
                        >
                          {data.default[language].table.gebruiker}
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow className={classes.tablerow} key={row.name}>
                        <TableCell
                          className={classes.tablecell}
                          component="th"
                          scope="row"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.personeelsnr}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.volgnr}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.bsn}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.hoofdgroep}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.docgroep}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.doctype}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.tabblad}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.datumdoc}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.documentnaam}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.pdfnaam}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.opmerking}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.datumanmaak}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.tijd}
                        </TableCell>
                        <TableCell className={classes.tablecell} align="right">
                          {row.gebruiker}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              flex={1}
              padding={1}
              paddingRight={10}
            >
              <Pagination
                page={Number(pageNumber)}
                count={Math.ceil(totalCount / ROWS_PER_PAGE)}
                shape="rounded"
                color="primary"
                showFirstButton
                showLastButton
                boundaryCount={2}
                renderItem={(item) => (
                  <PaginationItem
                    type={"start-ellipsis"}
                    component={Link}
                    selected
                    to={`/tablelog/${item.page}?sort=${sortOrder}`}
                    {...item}
                  />
                )}
              />
            </Box>
          </Container>
        </div>
      </main>
    </>
  );
};

export default TableLog;
