
const symbols = {
    "EUR":"€",
    "USD":"$",
    "BTC":"₿",
    "GBP":"£"
  }
export const extractNumber = (number) => {
    try {
        if (!number) return 0
        return parseFloat(number.toString().replace(",", ".")) || 0
    } catch (_) {
        return 0
    }
}

export const toExponentialNumber = (number,threshold = 100000000000) =>{
    return number > threshold ? number.toExponential(3) : number.toLocaleString()
}

export const getSymbol = (currency) =>{
   if(symbols.hasOwnProperty(currency)){
    return symbols[currency]
   }else{
    return ""
   }
}