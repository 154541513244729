import "./App.css";
import Home from "./Components/Home.js";
import Table from "./Components/Table.js";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Create from "./Components/Booking/Create";
import Edit from "./Components/Booking/Edit";
import List from "./Components/Booking/List";
import LoginPage from "./Components/Login/LoginPage";
import { PrivateRoute } from "./Components/Common/PrivateRoute";

function App() {
  return (
    <>
      <Router>
        <Switch>
          {/* <Route exact path="/login" component={LoginPage} /> */}
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/create" component={Create} />
          <PrivateRoute
            exact
            path="/edit/:id"
            component={Edit}
          />
          <PrivateRoute exact path="/booking/:pageNumber?" component={List} />
          <PrivateRoute exact path="/tablelog/:pageNumber" component={Table} />
          <PrivateRoute exact path="/tablelog/" component={Table} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
