const BASE_API = process.env.REACT_APP_API_URL || 'http://localhost:5050'
const AUTH_USERNAME = process.env.REACT_APP_AUTH_USERNAME
const AUTH_PASSWORD = process.env.REACT_APP_AUTH_PASSWORD
const apiConfig = {
    BASE_API,
    AUTH_USERNAME,
    AUTH_PASSWORD
};

export default apiConfig;

export const ALLOWED_FILE_EXTENSIONS = ["pdf", "jpg", "jpeg", "png"];