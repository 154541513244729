import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import { bookingApiService } from '../../services/BookingApi'
import auth from '../../features/auth/authSlice'
import config from '../../features/config/configSlice'
export const store = configureStore({
  reducer: {
    [bookingApiService.reducerPath]: bookingApiService.reducer,
    auth,
    config
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(bookingApiService.middleware),
  devTools: process.env.NODE_ENV === 'development',
})
setupListeners(store.dispatch);