import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiConfig from "../utilities/Constants";
import UserService from "../setup/oauth/UserService";

export const bookingApiService = createApi({
  reducerPath: "bookingApiService",
  baseQuery: fetchBaseQuery({
    validateStatus: (response) => {
      if (response.status === 401) {
        localStorage.removeItem("user_token");
        window.location.reload();
      }
      return response.status < 300;
    },
    baseUrl: `${apiConfig.BASE_API}/`,
    prepareHeaders: (headers, { getState }) => {
      const token = UserService.getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Bookings"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/api/auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    getBookings: builder.query({
      query: ({ page = 1, perPage = 10, sort = "?sort=id" }) =>
        `booking${sort || "?sort=id"}&page=${page}&perPage=${perPage}`,
      providesTags: ["Bookings"],
    }),
    getBookingById: builder.query({
      query: (id) => `booking/${id}`,
      providesTags: (result, error, id) => [{ type: "Bookings", id }],
      skip: (id) => !id,
    }),
    deleteBooking: builder.mutation({
      query: (id) => ({
        url: `/booking/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Bookings"],
    }),
    addNewBooking: builder.mutation({
      query: (payload) => ({
        url: "/booking/create",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Bookings"],
    }),
    updateBooking: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `booking/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: ["Bookings"],
    }),
  }),
});

export const {
  useLoginMutation,
  useGetBookingsQuery,
  useGetBookingByIdQuery,
  useDeleteBookingMutation,
  useAddNewBookingMutation,
  useUpdateBookingMutation,
} = bookingApiService;
