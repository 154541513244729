import UserService from "../oauth/UserService";

// const username = apiConfig.AUTH_USERNAME;
// const password = apiConfig.AUTH_PASSWORD;

// const authToken = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

export default function setupAxios(axios) {
  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(
    (config) => {
      if (!config.headers["Authorization"]) {
        const token = UserService.getToken();

        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        localStorage.removeItem("user_token");
        window.location.reload();
      }
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem("user_token");
        window.location.reload();
      }
      return Promise.reject(error.message);
    }
  );
}
