import { Container, CssBaseline, Grid, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Language from "./Language.js";
import UploadInvoice from "./UploadInvoice";
import * as data from "./locales/default.json";
import { logout } from "../features/auth/authSlice.js";
import { getCurrentLanguage } from "../features/config/configSlice.js";
import UserService from "../setup/oauth/UserService.js";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "#112240",
  },
  appbar: {
    background: "#aa00cc",
  },
  container: {
    marginTop: "200px",
    borderRadius: "10px",
  },
  listItem: {
    cursor: "pointer",
    color: "#a8b2d1",
  },
  ListItemText: {
    fontSize: "24px",
  },
  pageHeader: {
    fontSize: "42px",
    color: "#ccd6f6",
  },
});

const Home = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const classes = useStyles();
  const language = useSelector(getCurrentLanguage);
  const logoutClicked = () => {
    UserService.doLogout()
    // dispatch(logout())
    // push("/login");
  }

  return (
    <>
      <CssBaseline />
      <main>
        <div>
          <Container
            className={classes.container}
            style={{ background: "#112240" }}
            maxWidth="sm"
          >
            <Grid container justify="flex-end">
              <Language />
            </Grid>
            <Typography
              className={classes.pageHeader}
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {data.default[language].title}
            </Typography>
            <List className={classes.root}>
              <Link to="/create">
                <ListItem className={classes.listItem}>
                  <ListItemAvatar>
                    <NoteAddIcon style={{ color: "#64ffda", fontSize: 36 }} />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.ListItemText }}
                    primary={data.default[language].create}
                  />
                </ListItem>
              </Link>
              <Link to="/booking/1">
                <ListItem className={classes.listItem}>
                  <ListItemAvatar>
                    <LibraryBooksIcon style={{ color: "#64ffda", fontSize: 36 }} />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.ListItemText }}
                    primary={data.default[language].booking}
                  />
                </ListItem>
              </Link>
              <Link to="/tablelog">
                <ListItem className={classes.listItem}>
                  <ListItemAvatar>
                    <AssignmentTurnedInIcon
                      style={{ color: "#64ffda", fontSize: 36 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.ListItemText }}
                    primary={data.default[language].log}
                  />
                </ListItem>
              </Link>
              <UploadInvoice classes={classes} />
              <ListItem onClick={() => logoutClicked()} className={classes.listItem}>
                <ListItemAvatar>
                  <PowerSettingsNewIcon
                    style={{ color: "#64ffda", fontSize: 36 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  classes={{ primary: classes.ListItemText }}
                  primary={data.default[language].logout}
                />
              </ListItem>
            </List>
          </Container>
        </div>
      </main>
    </>
  );
};

export default Home;
