import React from "react";
import Form from "./Form";
import { useGetBookingByIdQuery } from "../../services/BookingApi";
import { useParams, useHistory } from "react-router";
const initialValues = {
  pdfName: "",
  companyId: 1,
  invoiceNumber: "",
  invoiceIBAN: "",
  vendorName: "",
  customerName: "",
  invoiceDate: "",
  description: "",
  bookingLines: [
    {
      accountNumber: "",
      taxRate: "Hoog",
      amount: "",
      amountInEUR: 0,
      currency: "EUR",
      taxAmount: 0,
      taxAmountInEUR: 0,
    },
  ],
};
const Edit = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data: booking, isSuccess, isError } = useGetBookingByIdQuery(id);
  if (isError) {
    history.replace("/booking", { state: { deleted: false } });
  }
  return (
    <Form
      isEditMode={true}
      initialValues={isSuccess ? booking : initialValues}
      selectedItem={id}
    />
  );
};

export default Edit;
