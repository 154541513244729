import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';
const useStyles = makeStyles({
  dialogStyle: {
    "& .MuiDialogTitle-root":{
        padding: "0 24px",
        color:"#3c0b07"
    },
    "& .MuiSvgIcon-root":{
        fontSize:"4rem",
    },
    "& .MuiDialogContent-root":{
      paddingBottom:"0"
    },
    "& .MuiPaper-root": {
      background: "#cdd5e3",
      textAlign: "center",
      paddingBottom:"0px"
    },
  },
})
const ErrorDialog = (props) => {
  const classes = useStyles();
  const {errorMessage,setErrorMessage,lang} = props
  return (
    <Dialog
    open={errorMessage}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="xs"
    className={classes.dialogStyle}
  >
    
    <DialogContent>

          <ErrorOutlineIcon color="error" fontSize="large"/>
          <DialogTitle id="alert-dialog-title">{errorMessage}</DialogTitle>
      </DialogContent>
    <DialogActions>
    <Button onClick={() => setErrorMessage(null)} color="primary">
        {lang.okay}
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default ErrorDialog