import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "config",
  initialState: { language: localStorage.getItem("language") ?? 'nl' },
  reducers: {
    setLanguage: (state, {payload}) => {
      localStorage.setItem("language", payload);
      state.language = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setLanguage } = slice.actions;

export default slice.reducer;

export const getCurrentLanguage = (state) => state.config.language;
