import {
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as yup from "yup";
import { selectCurrentUser, setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../services/BookingApi";
import Language from "../Language";
import * as data from "../locales/default.json";
import { getCurrentLanguage } from "../../features/config/configSlice";
import UserService from "../../setup/oauth/UserService";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "#112240",
  },
  form: {
    width: "80%",
    margin: "0 auto",
    paddingBottom: "30px"
  },
  appbar: {
    background: "#aa00cc",
  },
  container: {
    marginTop: "200px",
    borderRadius: "10px",
  },
  listItem: {
    cursor: "pointer",
    color: "#a8b2d1",
  },
  ListItemText: {
    fontSize: "24px",
  },
  pageHeader: {
    fontSize: "42px",
    color: "#ccd6f6",
  },
  selectText: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: "#a8b2d1",
    },
    "& .MuiInputLabel-root": {
      color: "#a8b2d1",
    },
    "& .MuiFormLabel-root": {
      background: "#112240",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a8b2d1",
    },
    "&.error": {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fd4f57",
        "&:hover": {
          borderColor: "#fd4f57",
        },
      },
    },
    "&.check": {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9aff82",
        "&:hover": {
          borderColor: "#9aff82",
        },
      },
    },
    "&.warn": {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fdc74f",
        "&:hover": {
          borderColor: "#fdc74f",
        },
      },
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#a8b2d1",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#a8b2d1",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#a8b2d1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#64ffda",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#64ffda",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#64ffda",
    },
  },
  button: {
    background: "#0c987e",
    borderRadius: 3,
    border: "1px solid #64ffda",
    color: "white",
    height: 48,
    margin: "24px 0",
    padding: "0 30px",
    "&:hover": {
      backgroundColor: "#1bb598",
    },
    "&.Mui-disabled": {
      color: "white",
    },
  },
  formError: {
    textAlign: "start",
    color: "#CF162B",
    margin: "0 8px",
    fontSize: "0.87rem",
  },
});
const loginSchema = (lang) =>
  yup.object().shape({
    username: yup
      .string()
      .label(lang.fields.username)
      .required(lang.fields.isRequired),
    password: yup
      .string()
      .label(lang.fields.password)
      .required(lang.fields.isRequired),
  });

const LoginPage = () => {
  // const user = useSelector(selectCurrentUser);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const classes = useStyles();
  const language = useSelector(getCurrentLanguage);
  if (!UserService.isLoggedIn()) {
    UserService.doLogin();
  }
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },

    validationSchema: loginSchema(data.default[language]),
    onSubmit: async (values) => {
      try {
        const result = await login(values);
        if (result.data.status) {
          dispatch(setCredentials(result.data));
          push("/");
        } else {
          formik.setFieldError("password", data.default[language].loginError);
        }
      } catch (err) {
        formik.setFieldError("password", data.default[language].failedToLogin);
      }
    },
  });
  // useEffect(()=>{
  //     if(user){
  //       push("/");
  //     }
  // },[user])
  return (
    <>
      <CssBaseline />
      <main>

        <div>

          <Container
            className={classes.container}
            style={{ background: "#112240" }}
            maxWidth="sm"
          >

            <Grid container justify="flex-end">
              <Language />
            </Grid>
            <Typography
              className={classes.pageHeader}
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {data.default[language].loginTitle}
            </Typography>

            <form
              onSubmit={formik.handleSubmit}
              className={classes.form}
              noValidate
            >
              <TextField
                className={classes.selectText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.username}
                name="username"
                variant="outlined"
                margin="normal"
                label={data.default[language].fields.username}
                SelectProps={{
                  classes: { icon: classes.icon },
                }}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className={classes.formError}>
                  {formik.errors.username}
                </div>
              ) : null}
              <TextField
                className={classes.selectText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.password}
                inputProps={{ type: "password" }}
                name="password"
                variant="outlined"
                margin="normal"
                label={data.default[language].fields.password}
                SelectProps={{
                  classes: { icon: classes.icon },
                }}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className={classes.formError}>
                  {formik.errors.password}
                </div>
              ) : null}
              <Button
                disabled={isLoading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                classes={{
                  root: classes.button,
                }}
              >
                {data.default[language].loginTitle}
              </Button>

            </form>

          </Container>
        </div>
      </main>
    </>
  );
};

export default LoginPage;
