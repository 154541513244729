import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectCurrentUser } from "../../features/auth/authSlice";
import UserService from "../../setup/oauth/UserService";

export function PrivateRoute({ component: Component, ...rest }) {
  if (!UserService.isLoggedIn()) {
    UserService.doLogin();
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        UserService.isLoggedIn() ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
