import React from "react";
import DataTable from "react-data-table-component";
import * as data from "../locales/default.json";
import { makeStyles } from "@material-ui/core/styles";
import { Container, CssBaseline, Typography } from "@material-ui/core";

const language = localStorage.getItem("language") || "nl";
const useStyles = makeStyles({
  pageHeader: {
    fontSize: "16px",
    color: "#ccd6f6",
    paddingTop: "8px",
  },
  table: {
    maxWidth: "97%",
    margin: "6px auto",
    border: "1px solid #999999",
    fontSize: ".2rem !important",
  },
});
const BookingLinesTable = ({ data: bookingLines }) => {
  const classes = useStyles();
  const columns = [
    {
      name: data.default[language].fields.currency,
      selector: (row) => row.currency,
      sortable: true,
      center: true,
      compact: true,
    },
    {
      name: data.default[language].fields.accountNumber,
      selector: (row) => row.accountNumberName,
      sortable: true,
      center: true,
      compact: true,
    },
    {
      name: data.default[language].fields.amount,
      selector: (row) => row.amount,
      sortable: true,
      center: true,
      compact: true,
      compact: true,
    },
    {
      name: data.default[language].fields.amountInEUR,
      selector: (row) => row.amountInEUR,
      sortable: true,
      center: true,
      compact: true,
    },
    {
      name: data.default[language].fields.taxRate,
      selector: (row) => row.taxRate,
      sortable: true,
      center: true,
      compact: true,
    },
    {
      name: data.default[language].fields.taxRate,
      selector: (row) => row.taxRateValue,
      sortable: true,
      center: true,
      compact: true,
    },
    {
      name: data.default[language].fields.taxAmount,
      selector: (row) => row.taxAmount,
      sortable: true,
      center: true,
      compact: true,
    },
    {
      name: data.default[language].fields.taxAmountInEUR,
      selector: (row) => row.taxAmountInEUR,
      sortable: true,
      center: true,
      compact: true,
    },
  ];
  return (
    <>
      <Typography
        className={classes.pageHeader}
        align="center"
        color="textPrimary"
        gutterBottom
      >
        {data.default[language].bookingLinesItems}
      </Typography>
      <DataTable
        className={classes.table}
        theme="dark"
        dense
        noHeader
        responsive
        columns={columns}
        data={bookingLines.bookingLines}
      />
    </>
  );
};

export default BookingLinesTable;
