import Keycloak from "keycloak-js";

const _kc = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_PORTAL_REALM,
  clientId: "app-invoice-dashboard",
});
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
_kc.onTokenExpired = () => {
  _kc
    .updateToken(5)
    .then(() => {})
    .catch(() => {});
};
_kc.onAuthLogout = () => {
  window.location.reload();
};
const initKeycloak = async (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
      messageReceiveTimeout: 90000,
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        UserService.doLogin();
      }
    })
    .catch((err) => {
      window.location.reload();
    });
};
const initEntitlement = () => {};
const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token || process.env.REACT_APP_SKIP_KEYCLOAK;

const updateToken = (successCallback) =>
  _kc.updateToken(1).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getUserAvatar = () => _kc.tokenParsed?.pic;
const getRealm = () => _kc.tokenParsed?.iss.split("/").at(-1);
const getGivenName = () => _kc.tokenParsed?.given_name;
const getFamilyName = () => _kc.tokenParsed?.family_name;
const getEmail = () => _kc.tokenParsed?.email;
const isEmailVarified = () => _kc.tokenParsed?.email_verified;
const getId = () => _kc.tokenParsed?.sid ?? "";
const getScope = () => _kc.tokenParsed?.scope;
const getTokenParsed = () => _kc.tokenParsed;
const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));
const hasResourceRole = (role, res) => _kc.hasResourceRole(role, res);
const role = _kc.roles;
const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getTokenParsed,
  hasRole,
  getRealm,
  role,
  getUserAvatar,
  getGivenName,
  getFamilyName,
  getEmail,
  isEmailVarified,
  getId,
  getScope,
  hasResourceRole,
  initEntitlement,
};

export default UserService;
