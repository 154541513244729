import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import setupAxios from "./setup/axios/SetupAxios";
import { store } from "./setup/redux/Store";

import "react-toastify/dist/ReactToastify.css";
import UserService from "./setup/oauth/UserService";
setupAxios(axios);

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Provider>
      {/* Same as */}
    </React.StrictMode>,
    document.getElementById("root")
  );
process.env.REACT_APP_SKIP_KEYCLOAK
  ? renderApp()
  : UserService.initKeycloak(renderApp);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
