import React from "react";
import Form from "./Form";

const Create = () => {
  const initialValues = {
    pdfName: "",
    companyId: 1,
    invoiceNumber: "",
    invoiceDate: null,
    invoiceIBAN: "",
    vendorName: "",
    paidById: 5,
    customerName: "",
    description: "",
    bookingLines: [
      {
        accountNumber: null,
        taxRate: "Hoog",
        amount: "",
        amountInEUR: 0,
        currency: "EUR",
        taxAmount: 0,
        taxAmountInEUR: 0,
      },
    ],
  };
  return <Form initialValues={initialValues} />;
};

export default Create;
